import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

import { setCookies } from './cookie';
import dev from '@configs/env.config.json';

const firebaseConfig = {
	apiKey: 'AIzaSyAAEDBWKrovFEv4plHMkeQVzCsCHnbREkY',
	authDomain: 'push-notification-df33e.firebaseapp.com',
	databaseURL: 'https://push-notification-df33e-default-rtdb.firebaseio.com',
	projectId: 'push-notification-df33e',
	storageBucket: 'push-notification-df33e.appspot.com',
	messagingSenderId: '810353425919',
	appId: '1:810353425919:web:a05f01d9de15d6c2d47bca',
	measurementId: 'G-7X7KX17TF3',
};

const app = initializeApp(firebaseConfig);

export function requestPermission({ isIos }: { isIos: boolean }) {
	// Check if notifications are supported
	if (typeof Notification === 'undefined') {
		console.error('Notification not supported');
		return;
	}
	Notification.requestPermission()
		.then(permission => {
			if (permission === 'granted') {
				console.log('Notification permission granted.');
				// Initialize Firebase
				// Initialize Firebase Cloud Messaging and get a reference to the service
				const analytics = getAnalytics(app);
				const messaging = getMessaging();

				getToken(messaging, {
					vapidKey: 'BNBm3JcV7n9aXc-0IrCi-OsUszs6wGpKDTedvVVuKmPs0yeUQw42GEUXoLXA_eBw5lbL1n8rQ2M1zt7mof-QNqQ',
				})
					.then(currentToken => {
						if (currentToken) {
							// Send the token to your server and update the UI if necessary
							try {
								const data = {
									tokens: [{ token: currentToken, isIos }],
								};

								// Define the URL of the endpoint you want to send the POST request to
								const url = `${dev.PROD_JOIN_BASEURL}/api/v1/subscriptions`;
								// const url = `${dev.DEV_JOIN_BASEURL}/api/v1/subscriptions`;

								const requestOptions: RequestInit = {
									method: 'POST',
									headers: {
										credentials: 'include',
										'Content-Type': 'application/json',
									},
									body: JSON.stringify(data), // Convert data object to JSON string if sending JSON data
								};

								fetch(url, requestOptions)
									.then(res => res.json())
									.then(data => {
										if (data.success === 200) setCookies('firebase-token', currentToken, 28);
									})
									.catch(error => {
										console.log(error);
									});
							} catch (error) {
								console.log('error in subscription: ', error);
							}
						} else {
							console.log('No registration token available. Request permission to generate one.');
						}
					})
					.catch(err => {
						console.log('An error occurred while retrieving token. ', err);
					});
			} else {
				console.log(' Notification Permission not granted.');
			}
		})
		.catch(error => console.log('error in fetching token: ', error));
}

export const onMessageListener = () => {
	// Initialize Firebase
	// Initialize Firebase Cloud Messaging and get a reference to the service
	const analytics = getAnalytics(app);
	const messaging = getMessaging(app);

	return new Promise(resolve => {
		onMessage(messaging, payload => {
			// console.log("onmessageListner", payload);
			resolve(payload);
		});
	});
};
